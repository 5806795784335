// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */

*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Set up a decent box model on the root element
 */

html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: $cdreams-roboto;
  font-size: 2rem;
}

img {
  display: block;
}

ul {
  margin-top: 30px;

  li {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}
