.maintenance-page {
  position: relative;
}

.maintenance-page__background-image {
  min-height: 100vh;
  background-image: url('../images/maintenance-background-image.png');
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.maintenance-page__title-top {
  font-family: $cdreams-gitesyregular;
  font-size: 10.6rem;
  color: #ffffff;
  text-align: center;
}

.maintenance-page__logo-box {
  @extend %pseudo-aspec-ratio;
  border: solid 8px #E7DFF0;
  width: 89%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 48%;
  }

  &::before {
    padding-top: 60%;
  }
}

.maintenance-page__logo-center {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.maintenance-page__text-bottom {
  color: #ffffff;
  font-family: $cdreams-bobbyjonessoft;
  font-size: 3.1rem;
  margin-top: 46px;
  text-align: center;
  line-height: normal;
}

@include media-breakpoint-between(xs, md) {
  .maintenance-page__title-top {
    font-size: calc(64px + (90 - 64) * (100vw - 360px) / (768 - 360));
    -webkit-marquee-increment: 0vw; //Safari fluid text fix from: https://www.sarasoueidan.com/blog/safari-fluid-typography-bug-fix/
  }
}

@include media-breakpoint-between(md, xl) {
  .maintenance-page__title-top {
    font-size: calc(64px + (106 - 64) * (100vw - 768px) / (1600 - 768));
  }

  .maintenance-page {
    .cdreamsvideograph-container-fluid {
      padding-right: var(--bs-gutter-x, 10.3rem);
      padding-left: var(--bs-gutter-x, 10.3rem);
    }
  }
}

@include media-breakpoint-up(md) {
  .maintenance-page__logo-box {
    border-width: 16px;
  }

  .maintenance-page {
    .section {
      padding: 40px 0;
    }
  }

  .maintenance-page__logo-box {
    width: 75%;
    max-width: 799px;
  }
}

@include media-breakpoint-up(xl) {
  .section {
    padding: 60px 0;
  }
}
