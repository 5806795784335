// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&display=swap');

@font-face {
  font-family: 'gistesyregular';
  src: url('../fonts/gistesy.woff2') format('woff2'),
  url('../fonts/gistesy.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bobbyjonessoft';
  src: url('../fonts/bobby-jones-soft.woff2') format('woff2'),
  url('../fonts/bobby-jones-soft.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
