// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Sans serif font family
/// @type List
$cdreams-roboto: 'Roboto', sans-serif;
$cdreams-open-sans: 'Open Sans', sans-serif;

/// Thin serif font family
/// @type List
$cdreams-gitesyregular: 'gistesyregular', serif !default;

$cdreams-bobbyjonessoft: 'bobbyjonessoft', serif !default;

/// Container's maximum width
/// @type Length
$max-width: 1180px !default;

/// Custom Bootstrap Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels

$grid-breakpoints: (
  xxs: 0,
  xs: 360px,
  sm: 576px,
  md: 768px,
  lg: 1080px,
  xl: 1440px,
  xxl: 1600px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1000px,
  xl: 1360px,
  xxl: 1540px
);
