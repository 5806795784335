.section {
  padding: 55px 0;
  position: relative;
}

.cdreamsvideograph-container-fluid {
  padding-right: var(--bs-gutter-x, 2.5rem);
  padding-left: var(--bs-gutter-x, 2.5rem);
}

@include media-breakpoint-up(md) {
  .section {
    padding: 60px 0;
  }
}

@include media-breakpoint-up(lg) {
  .section {
    padding: 90px 0;
  }
}

@include media-breakpoint-up(xl) {
  .container-split {
    display: flex;
    justify-content: flex-end;
    margin-right: 11%;
  }
}

@include media-breakpoint-between(md, xl) {
  .cdreamsvideograph-container-fluid {
    width: 100%;
    padding-right: var(--bs-gutter-x, 8.3rem);
    padding-left: var(--bs-gutter-x, 8.3rem);
    margin-right: auto;
    margin-left: auto;
  }

  .container-split {
    padding-right: 12.75%;
  }
}
