%button-reset {
  background: transparent;
  box-shadow: 0 0 0 transparent;
  border: 0 solid transparent;
  text-shadow: 0 0 0 transparent;

  &:hover {
    background: transparent;
    box-shadow: 0 0 0 transparent;
    border: 0 solid transparent;
    text-shadow: 0 0 0 transparent;
  }

  &:active {
    //outline: none;
    border: none;
  }

  &:focus {
    //outline: 0;
  }
}

%pseudo-aspec-ratio {
  &::before {
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
  }

  &::after { /* to clear float */
    content: '';
    display: table;
    clear: both;
  }
}
